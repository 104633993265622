import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "assets/icons/search.svg";
import DownIcon from "assets/icons/down.svg";
import { NavLink, useNavigate } from "react-router-dom";
import ShopByDepartment from "../../NavigationBar/ShopByDepartment";
import StoreLocation from "components/Header/MainHeader/StoreLocation";
import {
  updateSearchText,
  updateSearchType,
  getSearchResultList,
} from "redux/actions";
import useOnClickOutside from "hooks/useOnClickOutside";
import CategoryDropdown from "../SearchProducts/CategoryDropdown";
import SearchDropdown from "../SearchProducts/SearchDropdown";
import { getLocalStorageValue } from "config/helper";
import _ from "lodash";
import "./mobile-sidebar.scss";
import Departmentmenu from "components/Header/NavigationBar/NavMenu/Departmentmenu";

const MobileSidebar = (props) => {
  const { isMobileSidebar, onClose } = props;
  const refSearchTypeDropdown = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refSearchDropdown = useRef();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const [isShowSearchDropdown, setIsShowSearchDropdown] = useState(false);

  useOnClickOutside(refSearchDropdown, () => setIsShowSearchDropdown(false));

  const [isShowSearchTypeDropdown, setIsShowSearchTypeDropdown] =
    useState(false);

  const { menu: menuData, header_section: headerSectionData } = useSelector(
    ({ common }) => common.commonDetailsData
  );

  const { searchResultList, searchResultListLoading, searchType } = useSelector(
    ({ search }) => search
  );

  const { facebook_url, twitter_url, instagram_url, youtube_url } =
    headerSectionData;

  const {
    ecom_section: { allow_ecommerce = "" },
    reorder_section: { has_visible_reorder_menu },
  } = useSelector(({ common }) => common.commonDetailsData);

  const { isUserLoggedIn } = useSelector(({ user }) => user);
  const searchTypeDropDownOption = useSelector(
    ({ common }) => common.commonDetailsData.top_dropdown_section
  );

  const [subMenu, setSubMenu] = useState("");
  const [searchedText, setSearchedText] = useState("");

  let ecomSearchTypeDropDownOption =
    allow_ecommerce === "0" && has_visible_reorder_menu === "0"
      ? searchTypeDropDownOption?.filter(
          (option) => option.search_type !== "products"
        )
      : searchTypeDropDownOption;

  const handleChangeNavMenu = (menu_id) => {
    setSubMenu(subMenu === menu_id ? "" : menu_id);
  };

  const handleReplaceUrl = (url) => {
    window.location.href = url;
    onClose();
  };

  const headerSection = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const { allow_recipe } = headerSection;

  const couponDepartmentmenu = useSelector(
    ({ couponsDepartment }) => couponsDepartment.coupondepartmentData
  );
  
  const handleClickMenu = (menuItem) => {
    navigate(menuItem.new_page_id ? `custom-pages/${menuItem.new_page_id}` : menuItem.menu_url);
    onClose();
  };
  
  const menu = (ele) => {
    if (ele.submenu.length) {
      return (
        <div className="menu" key={ele.menu_id}>
          <div className="icon-text-wrapper">
          <span onClick={() => handleClickMenu(ele)}>
            {ele.menu_name}
          </span>
            <img
              src={DownIcon}
              alt="DownIcon"
              onClick={() => handleChangeNavMenu(ele.menu_id)}
            />
          </div>
          <div
            className={
              subMenu === ele.menu_id
                ? "sub-menu sub-menu-show"
                : "sub-menu sub-menu-hidden"
            }
          >
            {ele.submenu
                .filter((fls) => fls.menu_visible === "1")
                .map((el) => {
                  return el.menu_link === "1" ? (
                    el.menu_open_self === "0" ? (
                      <a
                        key={el.menu_id}
                        href={el.menu_url}
                        target="_blank"
                        rel="noreferrer"
                        className={
                          el.is_highlight_menu === "1" ? "highlight-menu" : ""
                        }
                      >
                        <span>{el.menu_name}</span>
                      </a>
                    ) : (

                      <a
                        href={(e) => e.preventDefault()}
                        onClick={() => handleReplaceUrl(el.menu_url)}
                        key={el.menu_id}
                        className={el.is_highlight_menu === "1" ? "highlight-menu" : ""}
                      >
                        <span>{el.menu_name}</span>
                      </a>
                      
                    )
                  ) : (
                    <NavLink
                      key={el.menu_id}
                      to={el.new_page_id ? `custom-pages/${el.new_page_id}` : el.menu_url}
                      className={
                        el.is_highlight_menu === "1" ? "highlight-menu" : ""
                      }

                      onClick={() => onClose()}
                    >
                      <span>{el.menu_name}</span>
                    </NavLink>
                  );
                })}
          </div>
        </div>
      );
    } else {
      if (ele.show_modal === "0") {
        if (ele.menu_url === "recipes" && allow_recipe === "0") return null;
        return ele.menu_link === "1" ? (
          ele.menu_open_self === "0" ? (
            <div className="menu" key={ele.menu_id}>
              <a
                key={ele.menu_id}
                href={ele.menu_url}
                target="_blank"
                rel="noreferrer"
                className={ele.is_highlight_menu === "1" ? "highlight-menu" : ""}
                onClick={() => onClose()}
              >
                <span>{ele.menu_name}</span>
              </a>
            </div>
          ) : (
            <>
            {ele.new_page_id === "" ? (
              <div className="menu" key={ele.menu_id}>
            <a
              href={(e) => e.preventDefault()}
              onClick={() => handleReplaceUrl(ele.menu_url)}
              key={ele.menu_id}
              className={ele.is_highlight_menu === "1" ? "highlight-menu" : ""}
            >
              <span>{ele.menu_name}</span>
            </a>
            </div>
            ) : (
              <div className="menu" key={ele.menu_id}>
              <NavLink
                      key={ele.menu_id}
                      to={`custom-pages/${ele.new_page_id}`}
                      className={
                        ele.is_highlight_menu === "1" ? "highlight-menu" : ""
                      }
                      onClick={() => onClose()}
                    >
                      <span>{ele.menu_name}</span>
                    </NavLink>
              </div>
            )}
            </>
          )
        ) : (
          <div className="menu" key={ele.menu_id}>
          <NavLink
            key={ele.menu_id}
            to={ele.new_page_id ? `custom-pages/${ele.new_page_id}` : ele.menu_url}
            className={ele.is_highlight_menu === "1" ? "highlight-menu" : ""}
            target={ele.menu_open_self === "0" && "_blank"}
            onClick={() => onClose()}
          >
            <span>{ele.menu_name} </span>
          </NavLink>
          </div>
        );
      } else {
        return (
          <Departmentmenu
            menuName={ele.menu_name}
            couponDepartmentmenu={couponDepartmentmenu}
          />
        );
      }
    }
  };

  const handlechange = (e) => {
    setSearchedText(e.target.value);
    if (e.target.value) {
      getSearchResult.current(e.target.value);
    }
  };

  const getSearchResult = useRef(
    _.debounce((searchedText) => {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        search: searchedText,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };
      dispatch(getSearchResultList(body));
      setIsShowSearchDropdown(true);
    }, 500)
  );

  const handleToggleCategoyDropdown = () => {
    setIsShowSearchTypeDropdown(!isShowSearchTypeDropdown);
  };

  useOnClickOutside(refSearchTypeDropdown, () =>
    setIsShowSearchTypeDropdown(false)
  );
  const handleChangeSelectedOption = (optionValue) => {
    setIsShowSearchTypeDropdown(false);
    dispatch(updateSearchType(optionValue));
  };

  const { sub_department = [] } = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section || {}
  );

  let ecomSearchOptions = [...ecomSearchTypeDropDownOption];

  if (!!sub_department.length) {
    const updatedSubDepartment = sub_department.map((sub) => {
      return {
        ...sub,
        search_type: sub.slug,
      };
    });
    ecomSearchOptions.splice(1, 0, updatedSubDepartment);
    ecomSearchOptions = ecomSearchOptions.flat();
  } else {
    ecomSearchOptions = ecomSearchTypeDropDownOption;
  }
  const selectedSearchType =
    ecomSearchOptions &&
    ecomSearchOptions.find((type) => type.search_type === searchType);

  const categoryNameList = ecomSearchTypeDropDownOption.map(
    (cat) => cat.search_type
  );

  const handleSearchBar = () => {
    if (categoryNameList.includes(searchType)) {
      navigate(`/search/${searchType}/${searchedText}`);
      dispatch(updateSearchText(searchedText));
      onClose();
    } else {
      navigate(`/departments/${searchType}`);
      dispatch(updateSearchText(searchedText));
      onClose();
    }
  };

  const handleSearchbox = (e) => {
    const { value } = e.target;
    if (e.key === "Enter") {
      if (categoryNameList.includes(searchType)) {
        navigate(`/search/${searchType}/${value}`);
        dispatch(updateSearchText(value));
        setIsShowSearchDropdown(true);
        onClose();
      } else {
        navigate(`/departments/${searchType}`);
        dispatch(updateSearchText(value));
        setIsShowSearchDropdown(true);
        onClose();
      }
    }
  };

  const onSearchChange = (keyword) => {
    if (categoryNameList.includes(searchType)) {
      navigate(`/search/${searchType}/${keyword}`);
      dispatch(updateSearchText(keyword));
      onClose();
    } else {
      navigate(`/departments/${searchType}`);
      dispatch(updateSearchText(keyword));
      onClose();
    }
  };

  return (
    <>
      {isMobileSidebar && <div className="mobile-sidebar-backdrop"></div>}
      <div
        className={
          isMobileSidebar
            ? "mobile-sidebar mobile-sidebar-show"
            : "mobile-sidebar mobile-sidebar-hidden"
        }
      >
        <div className="mobile-header">
          <div>
            <NavLink to="/" onClick={() => onClose()}>
              <div>
                <img
                  src={headerSectionData.rsa_client_logo}
                  alt={headerSectionData.image_alt_text}
                />
              </div>
            </NavLink>
          </div>
          <div onClick={() => onClose()}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="mobile-menu">
          <div
            className="mobile-categories"
            onClick={() => handleToggleCategoyDropdown()}
            ref={refSearchTypeDropdown}
          >
            <div className="text">
              <span>
                All {selectedSearchType ? selectedSearchType.name : ""}
              </span>
              <img src={DownIcon} alt="DownIcon" />
            </div>

            <CategoryDropdown
              isShow={isShowSearchTypeDropdown}
              onClose={() => setIsShowSearchTypeDropdown(false)}
              dropdownData={ecomSearchOptions}
              onSelectOption={handleChangeSelectedOption}
              selectedSearchType={
                selectedSearchType && selectedSearchType.search_type
              }
            />
          </div>

          {/* <div className="input">
            <input
              type="text"
              // placeholder={`Search ${selectedSearchType?.name}`}
              onChange={handlechange}
              onKeyPress={handleSearchbox}
              value={searchedText}
            />
            <div className="right-icon-align" onClick={handleSearchBar}>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div> */}

          <div
            className="mobile-search-products"
            ref={refSearchDropdown}
            onClick={() => {
              setIsShowSearchDropdown(!isShowSearchDropdown);
            }}
          >
            <div className="relative-div">
              <input
                type="text"
                // placeholder={`${selectedSearchType?.name ?? ""}`}
                onChange={handlechange}
                onKeyPress={handleSearchbox}
                value={searchedText}
              />
              <div className="icon-wrapper" onClick={handleSearchBar}>
                <img src={SearchIcon} alt="SearchIcon" />
              </div>

              {searchResultList.length ? (
                <div
                  className={
                    isShowSearchDropdown
                      ? "mobile-search-dropdown dropdown-show"
                      : "mobile-search-dropdown dropdown-hidden"
                  }
                >
                  <div className="list-menu">
                    <ul>
                      {searchResultList.map((product) => {
                        return (
                          <SearchDropdown
                            key={product.keyword_id}
                            productKeyWord={product}
                            onSearchChange={onSearchChange}
                            setIsShowSearchDropdown={() =>
                              setIsShowSearchDropdown(false)
                            }
                          />
                        );
                      })}
                    </ul>
                  </div>
                </div>
              ) : (
                <div
                  className={
                    isShowSearchDropdown
                      ? "search-dropdown dropdown-show"
                      : "search-dropdown dropdown-hidden"
                  }
                >
                  {!searchResultListLoading && (
                    <div className="list-menu">
                      <ul>
                        <li>No Suggestion found.</li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {allow_ecommerce === "1" ? (
            <ShopByDepartment handleCloseMobileSider={onClose} />
          ) : (
            ""
          )}
          <div className="menu-list">
            {menuData.map((ele) => {
              return menu(ele);
            })}
            <div className="action-box">
              <StoreLocation onMobileSidebarClose={() => onClose()} />
              {isUserLoggedIn ? (
                <div className="auth-links"></div>
              ) : (
                <>
                  <div className="auth-links">
                    <div onClick={() => onClose()}>
                      <NavLink to="/login">Sign in</NavLink>
                    </div>
                    <div onClick={() => onClose()}>
                      <NavLink to="/signup">Register</NavLink>
                    </div>
                  </div>
                </>
              )}

              <div className="need-help">
                <div className="sub-title">
                  {"Need help? Call Us: "}
                  <a
                    href={`tel:+${headerSectionData.support_contact_number}`}
                    rel="noreferrer"
                  >
                    {headerSectionData.support_contact_number}
                  </a>
                </div>
              </div>
            </div>
            {(facebook_url || twitter_url || instagram_url || youtube_url) && (
              <div className="follow-us follow-us-hidden">
                <p>Follow us</p>
                <div className="social-align">
                  {facebook_url && (
                    <a href={facebook_url} target="_blank" rel="noreferrer">
                      <div>
                        <i className="fa-brands fa-facebook-f"></i>
                      </div>
                    </a>
                  )}

                  {twitter_url && (
                    <a href={twitter_url} target="_blank" rel="noreferrer">
                      <div>
                      <i className="fa-brands fa-x-twitter"></i>
                      </div>
                    </a>
                  )}

                  {instagram_url && (
                    <a href={instagram_url} target="_blank" rel="noreferrer">
                      <div>
                        <i className="fa fa-instagram"></i>
                      </div>
                    </a>
                  )}

                  {youtube_url && (
                    <a href={youtube_url} target="_blank" rel="noreferrer">
                      <div>
                        <i className="fa fa-youtube-play"></i>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileSidebar;
