import React from "react";
import { Link } from "react-router-dom";

import "./ads-section.scss";
import { useSelector } from "react-redux";
import loader from "assets/images/loader.gif";

const AdsSection = ({ weeklyAdsDetails }) => {
  const weeklyadDataLoading = useSelector(({ weeklyad }) => weeklyad.weeklyadDataLoading);
  return (
    <>
      {weeklyadDataLoading && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ width: '100px', height: '50px' }} src={loader} />
          <span>Loading, please wait....</span>
        </div>
      )}
      {!weeklyadDataLoading && (
        <div className="weekly-section-wrapper">
          <div className="container">
            <div className="grid">
              {weeklyAdsDetails
                ? weeklyAdsDetails.map((item) => {
                  return (
                    <div className="grid-items" key={item.ClientStoreId}>
                      <div className="box-title">
                        <h1>{item.ClientStoreName}</h1>
                      </div>
                      <div className="weekly-box">
                        <Link
                          to={`/weekly-ads/${item.ClientStoreId}/${item.ClientStoreName}`}
                        >
                          <div className="weekly-title-align">
                            <button>Weekly Ad</button>
                            <p>
                              {item.valid_from_date} - {item.valid_to_date}
                            </p>
                          </div>
                          <div className="weekly-image">
                            <img src={item.pdf_file_name} alt="weeklyimage" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })
                : ""}
            </div>
          </div>
        </div>
      )}

    </>
  );
};
export default AdsSection;
