import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRewardDetails } from "redux/actions";
import "./rewards-card.scss";
import DescriptionModal from "./Description";
import { getLocalStorageValue } from "config/helper";

const RewardsCard = () => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const {
    rewardData: { lm_rewards = [] },
  } = useSelector(({ reward }) => reward);

  const [modal, setModal] = useState(false);
  const [availablePoint, setAvailablePoint] = useState("");

  const Toggle = () => setModal(!modal);

  const handlePopup = (reward) => {
    Toggle();
    setAvailablePoint(reward);
  };

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getRewardDetails(body));
  }, []); // eslint-disable-line

  return (
    <>
      <section className="rewards-card-alignment">
        <div className="page-container">
          <div className="grid">
            {lm_rewards.length
              ? lm_rewards.map((reward) => {
                  return reward.reward_type_id === 2 ? (
                    reward.is_points_based ? (
                      <div className="grid-items">
                        <div className="card-sub-head-alignment">
                          <div className="reward-image-center-alignment">
                            <img src={reward.image_url} alt="RewardsImage" />
                          </div>
                          <h2>
                            {/* Earn <span>100</span> points on every{" "}
                            <span>$1</span> spent Available points{" "}
                            <span>10</span> pts */}
                            {reward.title}
                          </h2>
                          {isUserLoggedIn && (
                          <h2>
                            Available points{" "}
                            <span>{reward.purchased_amount}</span>
                          </h2>
                          )}
                        </div>
                        {!isUserLoggedIn && (
                          <div className="button-right-side-alignment">
                            <NavLink to="/login">
                              <button>
                                <i className="fa-solid fa-cart-shopping"></i>
                                <span>Sign in</span>
                              </button>
                            </NavLink>
                          </div>
                        )}
                        {reward.reward_details &&
                          <p>{reward.reward_details}</p>
                        }
                        {reward.reward_point_tiers &&
                          !!reward.reward_point_tiers.length && (
                            <div className="rewards-border">
                              <div className="rewards-header">
                                <h3>REWARD POINTS MILESTONES</h3>
                              </div>
                              <div className="rewards-card-body">
                                <table>
                                  <tr>
                                    <th align="center">Points</th>
                                    <th align="center">Discount</th>
                                  </tr>
                                  {reward.reward_point_tiers.map(
                                    (points, i) => {
                                      return (
                                        <tr key={i}>
                                          <td align="center">
                                            {points.points_required}
                                          </td>
                                          <td align="center">
                                            ${points.reward_coupon_amount}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </table>
                              </div>
                            </div>
                          )}
                        {isUserLoggedIn && !!reward.purchased_amount > 0 && (
                          <div className="modal__btn">
                            <a
                              href={() => false}
                              onClick={() => {
                                handlePopup(reward);
                              }}
                            >
                              Redeem
                            </a>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="grid-items">
                        <div className="card-sub-head-alignment">
                          <div className="reward-image-center-alignment">
                            <img src={reward.image_url} alt="RewardsImage" />
                          </div>
                          <h2>
                            {/* spend <span>$ 500</span> get <span>$5</span> */}
                            {reward.title}
                          </h2>
                          <div className="progrees-bar">
                            <div
                              className="progrees-bar-active"
                              style={{
                                width: `${
                                  (reward.purchased_amount /
                                    reward.buy_qty_amount) *
                                  100
                                }%`,
                              }}
                            >
                              {" "}
                              <span
                                style={{
                                  padding: `${
                                    (reward.purchased_amount /
                                      reward.buy_qty_amount) *
                                    100
                                  }%`,
                                }}
                              >
                                ${reward.purchased_amount}
                              </span>
                            </div>
                          </div>
                          <div className="progrees-bar-label">
                            <h6>${reward.min_amount}</h6>

                            <h6>
                              {`${reward.purchased_amount}` ===
                              reward.buy_qty_amount
                                ? "Your free reward on the way"
                                : ""}
                            </h6>

                            <h6>${reward.buy_qty_amount}</h6>
                          </div>
                        </div>
                        {!isUserLoggedIn && (
                          <div className="button-right-side-alignment">
                            <NavLink to="/login">
                              <button>
                                <i className="fa-solid fa-cart-shopping"></i>
                                <span>Sign in</span>
                              </button>
                            </NavLink>
                          </div>
                        )}
                        {reward.reward_details &&
                          <p>{reward.reward_details}</p>
                        }
                        {/*  <div className="rewards-border">
                          <div className="rewards-header">
                            <h3>REWARD POINTS MILESTONES</h3>
                          </div>
                          <div className="rewards-card-body">
                            <table>
                              <tr>
                                <th align="center">Points</th>
                                <th align="center">Discount</th>
                              </tr>
                              <tr>
                                <td align="center">50,000</td>
                                <td align="center">$4</td>
                              </tr>
                              <tr>
                                <td align="center">50,000</td>
                                <td align="center">$4</td>
                              </tr>
                              <tr>
                                <td align="center">50,000</td>
                                <td align="center">$4</td>
                              </tr>
                              <tr>
                                <td align="center">50,000</td>
                                <td align="center">$4</td>
                              </tr>
                            </table>
                          </div>
                    </div>*/}
                      </div>
                    )
                  ) : (
                    <div className="grid-items">
                      <div className="card-sub-head-alignment">
                        <div className="reward-image-center-alignment">
                          <img src={reward.image_url} alt="RewardsImage" />
                        </div>
                        <h2>{reward.title}</h2>
                        <div className="round-progress-alignment">
                          {Array.from(Array(reward.buy_qty_amount).keys()).map(
                            (qtn) => {
                              return reward.purchased_qty > qtn ? (
                                <div className="round-active"></div>
                              ) : (
                                <div></div>
                              );
                            }
                          )}
                        </div>
                        <div className="total-counter">
                          <span className="counter-value">
                            {reward.buy_qty_amount === reward.purchased_qty &&
                            reward.purchased_qty !== 0
                              ? "Your free reward is on the way"
                              : reward.buy_qty_amount -
                                reward.purchased_qty +
                                " left"}
                          </span>
                        </div>
                      </div>
                      {!isUserLoggedIn && (
                        <div className="button-right-side-alignment">
                          <NavLink to="/login">
                            <button>
                              <i className="fa-solid fa-cart-shopping"></i>
                              <span>Sign in</span>
                            </button>
                          </NavLink>
                        </div>
                      )}
                      {reward.reward_details &&
                          <p>{reward.reward_details}</p>
                        }
                      {reward.reward_products &&
                        !!reward.reward_products.length && (
                          <div className="rewards-border">
                            <div className="rewards-header">
                              <h3>REWARD PRODUCTS</h3>
                            </div>
                            <div className="rewards-card-body">
                              <table>
                                <tr>
                                  <th align="center" className="rewards-h1">
                                    Product Name
                                  </th>
                                  <th align="center" className="rewards-h2">
                                    UPC
                                  </th>
                                </tr>
                                {reward.reward_products.map((points, i) => {
                                  return (
                                    <tr key={i}>
                                      <td align="center" className="upc-table">
                                        {points.product_name}
                                      </td>
                                      <td align="center">{points.upc}</td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                        )}
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </section>
      {modal && (
        <DescriptionModal
          show={modal}
          title="My Modal"
          close={Toggle}
          reward={availablePoint}
        />
      )}
    </>
  );
};

export default RewardsCard;
