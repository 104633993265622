import React, { useEffect, useRef, useState } from "react";
import "./checkout-invoice.scss";
import Logo from "assets/logo/footer-logo.svg";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrderDetails,
  getCateringMealsOrderDetails,
  cancelOrder,
} from "redux/actions";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
import ConfirmationModal from "./ConfirmationModal";
import { getLocalStorageValue } from "config/helper";

const CheckoutInvoice = () => {
  const { orderid, invoiceType, mealKitId } = useParams();
  const componentRef = useRef();

  const dispatch = useDispatch();

  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const { orderdetailsData: singleOrderData, cancelOrderLoading } = useSelector(
    ({ customer }) => customer
  );
  const { order_cancelled_flag = "0" } = singleOrderData;
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const { facebook_url, twitter_url, rsa_client_logo } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const [isShowConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    if (invoiceType === "catering-meal") {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: storeId,
        meal_order_id: mealKitId,
        AppName: isUserLoggedIn ? "shop" : "",
        Version: isUserLoggedIn ? "1" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };
      dispatch(getCateringMealsOrderDetails(body));
    } else {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: storeId,
        order_id: orderid,
        AppName: isUserLoggedIn ? "shop" : "",
        Version: isUserLoggedIn ? "1" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };
      dispatch(getOrderDetails(body));
    }
  }, [dispatch, clientId, userToken, storeId, orderid, invoiceType, mealKitId]); // eslint-disable-line

  const dateFormat = (inputDate, format) => {
    const date = new Date(inputDate);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    format = format.replace("MM", month.toString().padStart(2, "0"));

    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  };

  const formatPhoneNumber = (str) => {
    let cleaned = ("" + str).replace(/\D/g, "");

    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  };

  const handleShowConfirmationPopup = () => {
    setShowConfirmationPopup(true);
  };

  const handleCancelOrder = () => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      order_id: orderid,
      customer_cancel_notes: "General Inquiry",
      AppName: isUserLoggedIn ? "shop" : "",
      Version: isUserLoggedIn ? "1" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(cancelOrder(body)).then((res) => {
      if (invoiceType === "catering-meal") {
        const body = {
          RSAClientId: clientId,
          ClientStoreId: storeId,
          meal_order_id: mealKitId,
          AppName: isUserLoggedIn ? "shop" : "",
          Version: isUserLoggedIn ? "1" : "",
          member_number: isUserLoggedIn ? memberNumber : "",
          user_token: isUserLoggedIn ? userToken : "",
          DeviceType: isUserLoggedIn ? "web" : "",
        };
        dispatch(getCateringMealsOrderDetails(body));
      } else {
        const body = {
          RSAClientId: clientId,
          ClientStoreId: storeId,
          order_id: orderid,
          AppName: isUserLoggedIn ? "shop" : "",
          Version: isUserLoggedIn ? "1" : "",
          member_number: isUserLoggedIn ? memberNumber : "",
          user_token: isUserLoggedIn ? userToken : "",
          DeviceType: isUserLoggedIn ? "web" : "",
        };
        dispatch(getOrderDetails(body));
      }
    });
  };

  const backToAccountUrl =
    invoiceType === "catering-meal"
      ? "/myaccount/cateringMealsOrders"
      : "/myaccount/myOrder";

  return (
    <>
      {singleOrderData && (
        <div>
          <div style={{ display: "none" }}>
            <ComponentToPrint ref={componentRef} />
          </div>
          <div className="container">
            <div className="checkout-invoice-wrapper">
              <Link to={backToAccountUrl}>
                <div className="back-to-account-button">
                  <button className="backBtn">Back To Account</button>
                </div>
              </Link>
              <div className="checkout-invoice-header">
                <div>
                  <img src={rsa_client_logo} alt="Logo" className="img-responsive" />
                </div>

                <div>
                  <p>
                    Date:{" "}
                    {Object.keys(singleOrderData).length > 0
                      ? dateFormat(
                          singleOrderData.order_placed_on.split(" ")[0],
                          "MM-dd-yyyy"
                        )
                      : ""}
                  </p>
                  <h2>Order #: {singleOrderData.order_number}</h2>
                  <br />
                </div>
              </div>
            </div>

            <div className="invoice-body-wrapper">
              <div className="invoice-grid invoice-grid-wrapper">
                <div className="invoice-grid-items">
                  <p>{singleOrderData.shipping_method_name}</p>
                  <h5>
                    {singleOrderData.AddressLine1},{" "}
                    {singleOrderData.AddressLine2} <br />
                    {singleOrderData.City}, {singleOrderData.StateName} - {singleOrderData.ZipCode}, USA
                    <br />
                    Phone: {formatPhoneNumber(singleOrderData.StorePhoneNumber)}
                    <br />
                  </h5>
                </div>
                <div className="invoice-grid-items">
                  <p>Customer</p>
                  <span>
                    {singleOrderData.customer_first_name}{" "}
                    {singleOrderData.customer_last_name}
                  </span>
                  <font>
                    {singleOrderData.customer_email}
                    <br />
                    Phone:
                    {formatPhoneNumber(
                      singleOrderData.order_perferred_mobile_number
                    )}
                    {singleOrderData.billing_address_1}{" "}
                    {singleOrderData.billing_address_2}
                    <br />
                    {singleOrderData.billing_city}{" "}
                    {singleOrderData.billing_state}
                  </font>
                </div>
              </div>
              <div className="invoice-grid">
                <div className="invoice-grid-items">
                  <p>
                    {singleOrderData.shipping_method === "store_pickup"
                      ? "Pickup Date"
                      : "Delivery Date"}
                  </p>
                  <font>
                    {dateFormat(singleOrderData.shipping_date, "MM-dd-yyyy")}
                  </font>
                </div>
                <div className="invoice-grid-items">
                  <p>Payment Method</p>
                  <font>Via {singleOrderData.payment_method_name}</font>
                  <br />
                  <br />

                  <p>Status</p>
                  <font>
                    {Object.keys(singleOrderData).length > 0
                      ? singleOrderData.order_status_name ||
                        singleOrderData.status
                      : ""}
                  </font>
                  {order_cancelled_flag === 1 && (
                    <button
                      className="cancel-order-button"
                      disabled={cancelOrderLoading}
                      onClick={handleShowConfirmationPopup}
                    >
                      {cancelOrderLoading ? "Cancelling order" : "Cancel Order"}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="invoice-table-wrapper">
              <div className="table-responsive">
                <table>
                  <tr>
                    <th align="left">Item Name</th>
                    <th align="left">Unit Price</th>
                    <th align="left">Quantity</th>
                    <th align="right">Amount</th>
                  </tr>
                  {Object.keys(singleOrderData).length > 0 &&
                    singleOrderData.order_item_list.map(
                      (order_item_list, index) => {
                        const unitPrice = order_item_list.price
                          ? +order_item_list.price
                          : 0;
                        const totalAmount = order_item_list.row_total
                          ? +order_item_list.row_total
                          : 0;
                        return (
                          <tr key={index}>
                            <td>
                              <span>{order_item_list.name}</span>
                              <span>UPC: {order_item_list.sku}</span>
                            </td>
                            <td>${+unitPrice.toFixed(2) ?? "0.00"}</td>
                            <td>{order_item_list.qty}</td>
                            <td className="last-amount">
                              ${totalAmount.toFixed(2) ?? "0.00"}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      {" "}
                      <span className="last-amount">Subtotal</span>
                    </td>
                    <td className="last-amount">
                      <span className="green-text-color">{`$${singleOrderData.amount}`}</span>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      {" "}
                      <span className="last-amount">
                        Additional Authorized Amount
                      </span>
                    </td>
                    <td className="last-amount">
                      <span className="green-text-color">{`$${
                        singleOrderData.additional_authorization_amount
                          ? singleOrderData.additional_authorization_amount
                          : "0.00"
                      }`}</span>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <span className="last-amount">Shipping</span>
                    </td>
                    <td className="last-amount">
                      <span className="green-text-color">
                        {singleOrderData.shipping_method_name}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      {" "}
                      <span className="last-amount">Total</span>
                    </td>
                    <td className="last-amount">
                      <span className="green-text-color">{`$${singleOrderData.total_amount}`}</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div className="checkout-items-card">
              {Object.keys(singleOrderData).length > 0 &&
                singleOrderData.order_item_list.map(
                  (order_item_list, index) => {
                    const unitPrice = order_item_list.price
                      ? +order_item_list.price
                      : 0;
                    const totalAmount = order_item_list.row_total
                      ? +order_item_list.row_total
                      : 0;
                    return (
                      <div className="checkout-card" key={index}>
                        <div className="checkout-card-details">
                          <div className="item-name">
                            <b>Item Name</b>
                            <div>
                              <span>{order_item_list.name}</span>
                              <div>UPC: {order_item_list.sku}</div>
                            </div>
                          </div>
                          <div>
                            <br />
                            <ul className="item-details">
                              <li>
                                <b>Unit Price</b>
                                <div>${+unitPrice.toFixed(2) ?? "0.00"}</div>
                              </li>
                              <li>
                                <b>Quantity</b>
                                <div>{order_item_list.qty}</div>
                              </li>
                              <li>
                                <b>Amount</b>
                                <div>${totalAmount.toFixed(2) ?? "0.00"}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}

              <br />

              <br />
              <div className="invoice-wrapper">
                <div className="checkout-invoice-information">
                  <div className="checkout-invoice-text-alignment">
                    <div>
                      <p>Additional Authorized Amount</p>
                    </div>
                    <div>
                      <h2>{`$${singleOrderData.additional_authorization_amount}`}</h2>
                    </div>
                  </div>
                  <div className="checkout-invoice-text-alignment">
                    <div>
                      <p>Subtotal</p>
                    </div>
                    <div>
                      <h2>{`$${singleOrderData.amount}`}</h2>
                    </div>
                  </div>

                  <div className="checkout-invoice-text-alignment">
                    <div>
                      <p>Shipping</p>
                    </div>
                    <div>
                      <h2> {singleOrderData.shipping_method_name}</h2>
                    </div>
                  </div>
                  <div className="checkout-invoice-text-alignment">
                    <div>
                      <p>Total</p>
                    </div>
                    <div>
                      <h2>{`$${singleOrderData.total_amount}`}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="important-note-wrapper">
              <div className="notes-text">
                <h5>Important Note</h5>
                <ul>
                  <li>All amounts shown on the invoice are in US Dollars.</li>
                  <li>
                    {
                      singleOrderData.additional_authorization_percentage_on_total_order
                    }{" "}
                    Additional temp hold for charges **{" "}
                  </li>
                  <li>Once an order is placed, money can't be refunded.</li>
                </ul>
              </div>
              <div className="thank-text">
                <h6>Thank you for your purchase</h6>
                {(facebook_url || twitter_url) && (
                  <div className="follow-us-wrapper">
                    <span>Follow us</span>
                    <div className="social-media-wrapper">
                      {facebook_url && (
                        <div>
                          <a
                            href={facebook_url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fa-brands fa-facebook-f"></i>
                          </a>
                        </div>
                      )}
                      {twitter_url && (
                        <div>
                          <a
                            href={twitter_url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fa-brands fa-x-twitter"></i>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="invoice-footer-button">
            <ReactToPrint
              trigger={() => (
                <button>
                  <i className="fa-solid fa-print"></i>
                  <span>Print and Download Invoice</span>
                </button>
              )}
              content={() => componentRef.current}
            />
            {/* <ReactToPrint
              trigger={() => <button>
                <i className="fa-solid fa-download"></i>
                <span>Download Invoice</span>
              </button>}
              content={() => componentRef.current}
            /> */}

            {isShowConfirmationPopup && (
              <ConfirmationModal
                isShow={isShowConfirmationPopup}
                onClose={() => setShowConfirmationPopup(false)}
                onClear={() => handleCancelOrder()}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutInvoice;
