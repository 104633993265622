import React from "react";
import "./download-checkout-invoice.scss";
import Logo from "assets/logo/footer-logo.svg";
import { useSelector } from "react-redux";


const CheckoutInvoice = React.forwardRef((props, ref) => {

    const singleOrderData = useSelector(
        ({ customer }) => customer.orderdetailsData
    );

    const { rsa_client_logo } = useSelector(
        ({ common }) => common.commonDetailsData.header_section || {}
      );

    const dateFormat = (inputDate, format) => {
        const date = new Date(inputDate);

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        format = format.replace("MM", month.toString().padStart(2, "0"));


        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2, 2));
        }
        format = format.replace("dd", day.toString().padStart(2, "0"));

        return format;
    }


    const formatPhoneNumber = (str) => {
        let cleaned = ('' + str).replace(/\D/g, '');

        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };

        return null
    };



    return (
        <>
            {singleOrderData && (
                <div ref={ref}>
                    <div className="download-checkout-invoice-wrapper">
                        <div className="checkout-invoice-header">
                            <div>
                                <img src={rsa_client_logo} alt="Logo" />
                            </div>

                            <div>
                                <p className="date">Date: {Object.keys(singleOrderData).length > 0 ? dateFormat(singleOrderData.order_placed_on.split(' ')[0], 'MM-dd-yyyy') : ''}</p>
                                <h2 className="order">Order #: {singleOrderData.order_number}</h2>
                                <br />

                            </div>
                        </div>

                    </div>

                    <div className="download-invoice-body-wrapper">
                        <div className="invoice-grid invoice-grid-wrapper">
                            <div className="invoice-grid-items">
                                <p><strong>{singleOrderData.shipping_method_name}: </strong>
                                    <div className="address">
                                        {singleOrderData.AddressLine1},{" "}
                                        {singleOrderData.AddressLine2} <br />
                                        {singleOrderData.City}, {singleOrderData.StateName} - {singleOrderData.ZipCode}, USA
                                        <br />
                                        Phone: {formatPhoneNumber(singleOrderData.StorePhoneNumber)}
                                        <br />
                                    </div></p>

                            </div>
                            <div className="invoice-grid-items">
                                <p><strong>Customer: </strong>
                                    <span>
                                        {singleOrderData.customer_first_name}{" "}
                                        {singleOrderData.customer_last_name}

                                    </span>
                                    <font>
                                        {singleOrderData.customer_email}<br />
                                        Phone:{formatPhoneNumber(singleOrderData.order_perferred_mobile_number)}

                                        {singleOrderData.billing_address_1} {singleOrderData.billing_address_2}
                                        <br />
                                        {singleOrderData.billing_city} {singleOrderData.billing_state}
                                    </font>
                                </p>
                            </div>
                            <div className="invoice-grid-items">
                                <p><strong>{singleOrderData.shipping_method === 'store_pickup' ? 'Pickup Date' : 'Delivery Date'}: </strong>
                                    <br /> <font>{dateFormat(singleOrderData.shipping_date, 'MM-dd-yyyy')}</font></p>


                            </div>
                            <div className="invoice-grid-items">
                                <p><strong>Payment Method: </strong><br /><font>Via {singleOrderData.payment_method_name}</font></p>

                            </div>
                            <div className="invoice-grid-items">
                                <p><strong>Status: </strong>
                                    <br />
                                    <font>{Object.keys(singleOrderData).length > 0 ? singleOrderData.order_status_name : ''}</font></p>

                            </div>
                        </div>
                    </div>
                    <div className="download-invoice-table-wrapper">
                        <div className="table-responsive">
                            <table>
                                <tr>
                                    <th align="left">Item Name</th>
                                    <th align="left">Unit Price</th>
                                    <th align="left">Quantity</th>
                                    <th align="left">Amount</th>
                                </tr>
                                {Object.keys(singleOrderData).length > 0 && (
                                    singleOrderData.order_item_list.map((order_item_list, index) => {

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <span>{order_item_list.name}</span>
                                                    <span>SKU: {order_item_list.sku}</span>
                                                </td>
                                                <td>{order_item_list.price}</td>
                                                <td>{order_item_list.qty}</td>
                                                <td>{order_item_list.row_total}</td>
                                            </tr>
                                        )
                                    }))}
                                <tr>
                                    <td>
                                        <span>Subtotal</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <span className="green-text-color">{`$${singleOrderData.amount}`}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span>Additional Authorized Amount</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <span className="green-text-color">{`$${singleOrderData.additional_authorization_amount ? singleOrderData.additional_authorization_amount : "0.00"}`}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span>Shipping</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <span className="green-text-color">
                                            {singleOrderData.shipping_method_name}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Total</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <span className="green-text-color">{`$${singleOrderData.total_amount}`}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="checkout-items-card">
                        {Object.keys(singleOrderData).length > 0 && (
                            singleOrderData.order_item_list.map((order_item_list, index) => {

                                return (

                                    <div className="checkout-card" key={index}>
                                        <div className="checkout-card-details">
                                            <div className="item-name">
                                                <b>Item Name</b>
                                                <div>
                                                    <span>{order_item_list.name}</span>
                                                    <div>SKU: {order_item_list.sku}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <br />
                                                <ul className="item-details">
                                                    <li>
                                                        <b>Unit Price</b>
                                                        <div>{order_item_list.price}</div>
                                                    </li>
                                                    <li>
                                                        <b>Quantity</b>
                                                        <div>{order_item_list.qty}</div>
                                                    </li>
                                                    <li>
                                                        <b>Amount</b>
                                                        <div>{order_item_list.row_total}</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                        <br />
                        <br />
                        <div className="invoice-wrapper">
                            <div className="checkout-invoice-information">
                                <div className="checkout-invoice-text-alignment">
                                    <div>
                                        <p>Additional Authorized Amount</p>
                                    </div>
                                    <div>
                                        <h2>{`$${singleOrderData.additional_authorization_amount}`}</h2>
                                    </div>
                                </div>
                                <div className="checkout-invoice-text-alignment">
                                    <div>
                                        <p>Subtotal</p>
                                    </div>
                                    <div>
                                        <h2>{`$${singleOrderData.amount}`}</h2>
                                    </div>
                                </div>

                                <div className="checkout-invoice-text-alignment">
                                    <div>
                                        <p>Shipping</p>
                                    </div>
                                    <div>
                                        <h2> {singleOrderData.shipping_method_name}</h2>
                                    </div>
                                </div>
                                <div className="checkout-invoice-text-alignment">
                                    <div>
                                        <p>Total</p>
                                    </div>
                                    <div>
                                        <h2>{`$${singleOrderData.total_amount}`}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="download-important-note-wrapper">
                        <div className="notes-text">
                            <h5>Important Note</h5>
                            <ul>
                                <li>All amounts shown on the invoice are in US Dollars.</li>
                                <li>{singleOrderData.additional_authorization_percentage_on_total_order} Additional temp hold for charges  ** </li>
                                <li>Once an order is placed, money can't be refunded.</li>
                            </ul>
                        </div>
                        <div className="download-thank-text">
                            <h6>Thank you for your purchase</h6>
                            <div className="follow-us-wrapper">
                                <span>Follow us</span>
                                <div className="social-media-wrapper">
                                    <div>
                                        <i className="fa-brands fa-facebook-f"></i>
                                    </div>
                                    <div>
                                    <i className="fa-brands fa-x-twitter"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default CheckoutInvoice;
